<template>
  <FadeIn 
  class="flex flex-col lg:flex-row lg:items-center gap-x-8 gap-y-6 lg:gap-y-20 lg:grid-cols-2 lg:items-start"
  :class="!hasTitle && sectionIndex === 0 ? '' : 'mt-20 lg:mt-32' "
  >
    <div 
    class="sm:px-6 lg:px-0 h-auto flex-1"
    :class="brickClasses"
    >
      <div class="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
        <ImageResponsive
        v-if="content?.image?.url"
        classes="rounded-xl ring-1 ring-white/10"
        :width="content?.image?.width"
        :height="content?.image?.height"
        :src="content?.image?.url"
        :alt="content?.image?.alternativeText"
        :lazy="true"
        :xs="12"
        :sm="6"
        :md="6"
        :lg="6"
        :xl="6"
        />
      </div>
    </div>
    <div 
    class="lg:pr-4 lg:pt-4 flex-1 flex justify-center"
    >
      <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
        <div><strong class="text-pretitle" v-if="content.pretitle">{{ content.pretitle }}</strong></div>
        <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ content.title }}</h2>
        
        <div 
        v-if="content.content"
        v-interpolation 
        v-html="sanitizeHtml" 
        class="prose lg:text-[1rem] lgleading-[1.75rem] mt-8">
        </div>

        <div 
        v-if="content.ctaUrl && content.ctaText"
        class="mt-8"
        >
          <ElementButton
          :url="content.ctaUrl" 
          type="primary"
          >
            {{ content.ctaText }}
          </ElementButton>
        </div> 
      </div>
    </div>
  </FadeIn>
</template>

<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  sectionIndex: {
    type: Number
  },
  hasTitle: {
    type: String,
    default: null
  }
})

const brickClasses = computed(() => {
  let brickClass = ''
  if (props.content?.imgGap) {
    if (props.sectionIndex && props.sectionIndex % 2 === 0) {
      brickClass += 'lg:pl-10'
    } else {
      brickClass += 'lg:pr-10'
    }
  }
  if (props.sectionIndex && props.sectionIndex % 2 === 1) {
    brickClass += ''
  } else {
    brickClass += ' lg:order-1'
  }
  return brickClass
})

const sanitizeHtml = computed(() => {
  let htmlContent = props.content.content

  const baseUrls = ['https://elker.com']
  const regex = /<a\s+([^>]*?)>/gi

  const updatedHtml = htmlContent.replace(regex, (match, attrs) => {
    let hrefMatch = attrs.match(/href\s*=\s*(['"])(.*?)\1/i)
    let href = hrefMatch ? hrefMatch[2] : ''
    let isLocal = href.startsWith('/') || baseUrls.some(baseUrl => href.startsWith(baseUrl))
    let hasTargetAttr = attrs.match(/target\s*=\s*(['"]).*?\1/i)

    if (isLocal) {
      attrs = attrs.replace(/target\s*=\s*(['"]).*?\1/i, '')
    } else if (!hasTargetAttr) {
      attrs += ' target="_blank" rel="dofollow"'
    }

    if (href.startsWith('https://elker.com')) { // make links relative
      attrs = attrs.replace(/href\s*=\s*(['"])(https:\/\/elker\.com)(.*?)\1/i, 'href="$3"')
    }

    if (isLocal && href.endsWith('/')) { // remove trailing slash
      attrs = attrs.replace(/href\s*=\s*(['"])(.*?)\/\1/i, 'href="$2"')
    }
    
    return `<a ${attrs}>`
  })

  return updatedHtml

})

</script>